import * as ReactDOMServer from 'react-dom/server';
import { DAYS_SHORT } from '../../../common';
import Swiper from 'swiper';
import {
  BULLET_ACTIVE_CLASS,
  BULLET_CLASS,
  BULLET_COMPLETED_CLASS,
  BULLET_FUTURE_CLASS,
  BULLET_TODAY_CLASS,
} from '../../constants/pagination';
import { isDayInFuture } from '../../../common/utils/isDayInFuture';
import { getDayIndexAsMondayFirst } from '../../../common/utils/getDayIndexAsMondayFirst';
import dayjs from 'dayjs';
import { SingleRow } from '../../view/home-page';

export type isDayCompletedType = (currentIterativeIndex: number) => boolean;

// Days in the week
const SLIDES_TO_RENDER = 7;

const currentDayIndexStartingFromMonday = getDayIndexAsMondayFirst();

// It assumes that only this week could be active
export const getPaginationItems =
  (data: SingleRow, isDayCompleted: isDayCompletedType) =>
  (swiper: Swiper, current: number, total: number) => {
    const sevenDaysClasses = new Array(SLIDES_TO_RENDER)
      .fill(1)
      .map((_, inx) => {
        const localeBasedIndex = inx + 1; // by default, it takes 0 as Sunday
        const currentIteratingDate = dayjs()
          .day(localeBasedIndex)
          .format('YYYY-MM-DD');
        return data.filter((clas) => clas.scheduledAt === currentIteratingDate);
      });

    return ReactDOMServer.renderToStaticMarkup(
      <>
        {new Array(SLIDES_TO_RENDER).fill(1).map((_, inx) => {
          const currentIteratingFirstVideoOfTheDay = sevenDaysClasses[inx][0];
          const isToday = currentDayIndexStartingFromMonday === inx;

          if (!currentIteratingFirstVideoOfTheDay) {
            return '';
          }

          const startingDaySlideId = currentIteratingFirstVideoOfTheDay.id;
          const isCurrentPaginationActive = sevenDaysClasses[inx].some(
            (clas) => clas.id === data[current - 1].id
          );

          return (
            <button
              className={`${BULLET_CLASS} ${
                isDayCompleted(inx) ? BULLET_COMPLETED_CLASS : ''
              }
              ${
                isDayInFuture(currentIteratingFirstVideoOfTheDay)
                  ? BULLET_FUTURE_CLASS
                  : ''
              }
              ${isCurrentPaginationActive ? BULLET_ACTIVE_CLASS : ''}
              ${isToday ? BULLET_TODAY_CLASS : ''}
              `}
              key={DAYS_SHORT[inx]}
              data-slide-id={startingDaySlideId}
            >
              {DAYS_SHORT[inx]}
            </button>
          );
        })}
      </>
    );
  };
